

const status = 'beta'  //总切换 beta/formal


var path = {}
if (status == 'beta') {
  path = { //beta地址
    ajaxUrlPath: 'https://api.app.huayanspace.com',
    spaceUrl: 'https://api.app.space.huayanspace.com',
    planetUrl: 'https://api.app.startuplanet.huayanspace.com',
    tiktokUrl: 'https://api.app.douyin.huayanspace.com',
    mobileUrl:'https://app.huayanspace.com',
  }
} else {     //正式地址
  path = {
    ajaxUrlPath: 'https://api.m.huayanspace.com',
    spaceUrl: 'https://api.space.huayanspace.com',
    planetUrl: 'https://api.startuplanet.huayanspace.com',
    tiktokUrl: 'https://api.douyin.huayanspace.com',
    mobileUrl:'https://m.huayanspace.com',
  }
}

import Vue from 'vue'
import App from './App.vue'
//引入路由
import router from './router'
import vueRouter from 'vue-router'
//引入接口请求方式vue-resource
import VueResource from 'vue-resource'
//引入mint ui
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'

//引入px2rem
import 'lib-flexible'
Vue.use(vueRouter)
Vue.use(VueResource)
Vue.use(MintUI)

Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.http.options.root = path.ajaxUrlPath;
Vue.http.options.emulateJSON = true;
Vue.http.options.credentials = true;//前端设置是否带cookie
Vue.config.productionTip = false;
Vue.prototype.$get = (url, obj={}) => {
  url = url.indexOf('http')==-1 ? url.ajaxUrlPath+url : url
  // url += '?hy_session=dq7alprqvdnabkcts2e8a4381c'
  // url += '?hy_session=e85gf8j0ceprkr8h0hm66var7g' //方便在微信中开发
  return new Promise((resolve, reject) => {
    Vue.http.get(url, {params: obj}).then(({data}) =>  {
      if (data.code == 0) {
        resolve(data.data)
      } else {
        reject(data)
      }
    }, () => Vue.prototype.$message({message:"网络错误",type:'error'}))
  })
}
Vue.prototype.ajaxUrlPath = path.ajaxUrlPath;
Vue.prototype.mobileUrl = path.mobileUrl;
Vue.prototype.spaceUrl = path.spaceUrl;
Vue.prototype.planetUrl = path.planetUrl;
Vue.prototype.tiktokUrl = path.tiktokUrl;
//手机正则
const country = [
  {label: '+86', country: '中国', country_code: 86, reg: /^1[3-9]{1}[0-9]{1}[0-9]{8}$/},
  {label: '+852', country: '中国香港', country_code: 852, reg: /^[0-9]{8}$/},
  {label: '+60', country: '马拉西亚', country_code: 60, reg: /^0?1(1\d{8}|[02-9]\d{7})$/},
];
Vue.prototype.$country = country
Vue.prototype.$checkMobile = (mobile) => {
  var t = false
  for (let i in country) {
    if (country[i].reg.test(mobile)) {
      t = true;
      return country[i];
    }
  }
  return t
};
//全局方法
Vue.prototype.$goback = () => {
  history.go(-1);
};
Vue.prototype.$formatTime = (timestamp,format='y-m-d') => {//y-m-d:年月日，y-m-d h:m:年月日时分，
  if (!timestamp) return ''
  let date = timestamp.toString().length<13 ? new Date(timestamp*1000) : new Date(timestamp);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  if (format == 'y-m-d h:m')
    return `${year}-${month}-${day} ${hours}:${minutes}`
  else if (format == 'y-m-d h:m:s')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  else if (format == 'y/m/d')
    return `${year}/${month}/${day}`
  else if (format == '年月日')
    return `${year}年${month}月${day}日`
  else
    return year + '-' + month + '-' + day;
}
let u = navigator.userAgent
if (u.indexOf('Mac OS X') > -1) {// ios
  Vue.prototype.$system = 'ios'
} else if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {// android
  Vue.prototype.$system = 'android'
}
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
